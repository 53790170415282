import { useJsonld } from '#jsonld';

export function useStructuredEvent(page) {
    const meta = page?.meta;
    const url = meta?.html_url;

    const course = page?.data;

    const courseFacts0 = course.facts.facts[0];
    let price;
    try {
        price = course.facts.facts[1][1].value.split(' ')[1].replace('-', '00');
    } catch {
        price = '';
    }

    const startTime = courseFacts0[1].value;
    const endTime = courseFacts0[2].value;

    const courseFacts1 = course.facts.facts[1];
    const courseLocation = courseFacts1[0].value;

    const startTimeArr = startTime.split(' ')[0].split(':');
    const endTimeArr = endTime.split(' ')[0].split(':');
    if (course.dates) {
        for (let date of course.dates) {
            const startDate = new Date(date.dateTimestamp * 1000); // Convert to milliseconds
            const endDate = new Date(date.dateTimestamp * 1000);

            startDate.setHours(startTimeArr[0]);
            startDate.setMinutes(startTimeArr[1]);

            endDate.setHours(endTimeArr[0]);
            endDate.setMinutes(endTimeArr[1]);

            useJsonld({
                '@context': 'https://schema.org',
                '@type': 'Event',
                name: page?.title,
                startDate: startDate.toISOString().split('.')[0], // Remove Timezone
                endDate: endDate.toISOString().split('.')[0],
                eventAttendanceMode:
                    'https://schema.org/OfflineEventAttendanceMode',
                eventStatus: 'https://schema.org/EventScheduled',
                location: {
                    '@type': 'Place',
                    name: courseLocation,
                },
                image: [course.image.src],
                description: course.description,
                offers: {
                    '@type': 'Offer',
                    url: url /* URL der aktuellen Seite */,
                    price: price /* Kurspreis */,
                    priceCurrency: 'CHF',
                    availability: 'https://schema.org/InStock',
                },
            });
        }
    }
}
