<template>
    <h2 class="scale-3 scale-3--bold-uppercase spacer-25-20" :id="anchorTitle">
        {{ t('course.groupRequest.title') }}
    </h2>
    <div class="equal-base spacer-30">
        {{ t('course.groupRequest.text') }}
    </div>
    <FormButton
        :label="t('course.groupRequest.request')"
        :disabled="disabled"
        @click="handleRequest()"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const { t } = useI18n();

const props = defineProps({
    requestEmail: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const handleRequest = () => {
    const recipient = props.requestEmail.recipient;
    const subject = encodeURIComponent(props.requestEmail.subject);
    const body = encodeURIComponent(props.requestEmail.template);

    const mailto = `mailto:
        ${recipient}
        ?subject=${subject}
        &body=${body}
    `.replace(/\s/g, '');

    window.location.href = mailto;
};

const anchorTitle = computed(() => {
    return useAnchorTitle(t('course.groupRequest.title'));
});
</script>

<style lang="scss" scoped></style>
