<template>
    <h2 class="scale-3 scale-3--bold-uppercase spacer-25-20" :id="anchorTitle">
        {{ t('course.registration.title') }}
    </h2>
    <div class="equal-base spacer-30">
        {{ t('course.registration.text') }}
    </div>
    <FormButton
        :label="t('course.registration.request')"
        class="spacer-40"
        :disabled="disabled"
        @click="handleRequest()"
    />
    <p class="equal-small-1 registration__info">
        {{ t('course.registration.information') }}
    </p>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const { t } = useI18n();

const props = defineProps({
    requestEmail: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const handleRequest = () => {
    const recipient = props.requestEmail.recipient;
    const subject = encodeURIComponent(props.requestEmail.subject);
    const body = encodeURIComponent(props.requestEmail.template);

    const mailto = `mailto:
        ${recipient}
        ?subject=${subject}
        &body=${body}
    `.replace(/\s/g, '');

    window.location.href = mailto;
};

const anchorTitle = computed(() => {
    return useAnchorTitle(t('course.registration.title'));
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.registration {
    &__info {
        color: #999999;
    }
}
</style>
