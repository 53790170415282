<template>
    <div class="row spacer-navigation-overlap spacer-50-40">
        <div class="col-12">
            <div class="course__image">
                <NuxtImg
                    sizes="xs:630 sm:767px md:1023px lg:1710px"
                    densities="1x 2x"
                    format="webp"
                    :src="course?.image?.src"
                    :alt="course?.image?.alt"
                    loading="lazy"
                    :placeholder="[300, 200, 60, 2]"
                />
            </div>
        </div>
    </div>
    <div class="row justify-content-center spacer-100-80">
        <div class="col-12 col-800-10 col-1200-8">
            <h1 class="scale-6 course__title">{{ title }}</h1>
            <p class="scale-2 scale-2--lead course__description">
                {{ course?.description }}
            </p>
        </div>
    </div>
    <CmsContentFacts v-if="course?.facts" v-bind="course?.facts" />
    <div v-if="page?.body.length > 0" class="spacer-140-100">
        <CmsBody :page="page" />
    </div>
    <div class="row justify-content-center spacer-footer">
        <div
            v-if="course?.dates.length"
            class="col-12 col-800-10 col-1200-8 spacer-140-100"
        >
            <CourseDates v-if="course.dates" :dates="course.dates" />
        </div>
        <div class="col-12 col-800-10 col-1200-8 spacer-140-100">
            <CourseRegistration
                :requestEmail="
                    course?.request_settings?.registration?.request_email
                "
                :disabled="course?.request_settings?.registration?.disabled"
            />
        </div>
        <div class="col-12 col-800-10 col-1200-8">
            <CourseGroupRequest
                :requestEmail="
                    course?.request_settings?.group_request?.request_email
                "
                :disabled="course?.request_settings?.group_request?.disabled"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStructuredEvent } from '~/composables/structuredData/event';

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

const title = computed(() => page?.value?.title);
const course = computed(() => page?.value?.data);

useStructuredEvent(page?.value);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.course {
    &__image {
        position: relative;
        display: flex;
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);
        overflow: hidden;

        @include media-breakpoint-down(560) {
            margin: 0 $padding-container-negative;
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            object-fit: cover;
        }
    }

    &__title {
        margin-bottom: 15px;
    }

    &__description {
        margin-bottom: 0;
    }
}
</style>
